@import url("https://fonts.googleapis.com/css?family=Permanent+Marker|Rock+Salt");

.player {
    display: flex;
    background-color: #F2F7F8;
    border-radius: 20px;
    width: 200px;
    height: 40px;
    margin-bottom: 20px;
    align-items: center;
    box-shadow: 3px 4px 15px #e2fcfc;
    margin-right: 10px;
    margin-top: 15px;
    box-shadow: 1px 2px 20px #d0f5f5;
}


.player .song .song__title {
    text-align: left;
    margin: 0;
    color: white;
    font-family: "Permanent Marker", cursive;
    font-weight: normal;
    font-size: 3.5em;
}

.player .song .song__title:hover {
    color: greenyellow;
}

.player .song .song__artist {
    margin: 0;
    color: white;
    font-family: "Rock Salt", cursive;
    font-weight: normal;
    font-size: 1em;
}

.player .song .song__artist:hover {
    color: greenyellow;
    cursor: pointer;
}

.player .controls {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
                text-align: center;
}

.player .player__button {
    background-color: transparent;
    border: none;
    padding: 0px;
}


.player .player__button:focus {
    outline: none;
}

.player .player__button:hover {
    cursor: pointer;
}

.player .player__button:hover svg {
    color: #4a99b1;
}

.player .player__button svg {
    font-size: 25px;
    color: #176E88;
}

.player .bar {
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;
}

.player .bar .bar__time {
    color: white;
    font-size: 16px;
}

.player .bar .bar__progress {
    flex: 1;
    border-radius: 5px;
    margin: 0 20px;
    height: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.player .bar .bar__progress .bar__progress__knob {
    position: relative;
    height: 16px;
    width: 16px;
    border: 1.5px solid white;
    border-radius: 50%;
    background-color: orange;
}

@media screen and (max-width: 100px) {
    .player {
        flex-direction: row;
    }

    .player .controls {
        width: 100%;
        margin-top: 20px;
    }

    .player .bar {
        width: 90%;
    }
}

.label{
    font-size: 14px;
    width: fit-content;
    flex: 4;
    color: #25768E;
    text-align: left;
}

@media screen and (max-width: 500px) {
    .player .song .song__title {
        font-size: 2.5em;
    }

    .player .song .song__artist {
        font-size: 0.8em;
    }
}
