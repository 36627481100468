.rhap_container {
  background: transparent !important;
  box-shadow: none !important;
  min-width: 50px !important;
  padding: 0px !important;
}



.audio-player {
  max-width: 160px;
  height: 40px;
  line-height: 35px;
  background: #F2F7F8 !important;
  vertical-align: middle;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 15px;
  box-shadow: 1px 1px 20px #e5ffff;
  
}

.audio-player-btn {
  background: transparent !important;
  padding: 0px;
  border-radius: 20px;
}

.audio-player-title {
  padding: 0px;
  vertical-align: middle;
  color:#190A32;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding-left: 0px;
}